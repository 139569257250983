import * as moment from 'moment'
import * as R from 'ramda'
import { t } from 'i18next'
import * as Offering from '../offering/types'
const { QuickTender, QuickTenderScopePending, noRatings, fromDomain, toDomain } = Offering
import { makeFlexibleOffering } from '../offering/recommendation'
import { upgradeToQuickTenderWithRatings } from '../offering/quick-tender'
import {
  Offer,
  Opportunity,
  AssessmentQuestion,
  AssessmentQuestionKey,
  AssessmentEquipmentAnswers,
  AssessmentEquipmentGroupAnswers,
  CommercialTerms,
  ClientFrontlineConfig,
  Criticality,
  AssessmentMethodType,
  DoorAssessmentQuestion,
  EquipmentAssessmentQuestion,
  EquipmentQuestionKey,
  Equipment,
  Building,
  EquipmentGroup,
  Grouping,
  EquipmentGroupStId,
  EquipmentGroups,
  BundleSelection,
  Bundle
} from '../../../../types/types'
import * as AssessmentResult from '../assessment-result/dto'
import * as L from 'partial.lenses'
import { setInitialData, setInitialWarrantyOptions } from '../offer'
import {
  fillPricesForExtraServices,
  extractServices,
  fillPricesForExtraCommercialTerms,
  extractCommercialTerms
} from '../pricing/extra-services'
import { collectGroups } from '../assessment-result/equipment'
import { translateXLAssessmentQuestionAnswerKey } from '../frontline-config/utils'
import { getQuestionForKey, getCriticalityKey } from './assessment-answers'
import { renderUserNotificationDialog } from '../../components/common/Dialog/UserNotification'
import { bundleDeactivationDialog, BundleGroupInfo } from '../../components/common/Dialog/BundleDeactivationDialog'
import * as dialog from '../dialog'
import { sync } from '../../sync/sync-manager'
import { IsOnline } from '../../state/global'
import { GroupStates, initGroupStates } from '../../components/OfferWizard/Offering/model'
import { getGroupOfferingQuestionsByOffering } from '../offering/questions'
import { initGroupValidationStates } from '../offering/validation'
import {
  AppliedBundle,
  BundlePath,
  applyOrRemoveBundleSelection,
  getAppliedBundles,
  getUpdateOffering
} from '../offering/bundle'
import { getGroupNameById } from '../../../../common/offer'
import { BundleUpdateError, NoOp, StateChange } from '../offering/state-change'
import {
  resetCommercialTerms,
  isMultiSelectCommercialTerm
} from '../../components/OfferWizard/CommercialTerms/CommercialTermsUtils'
import { getEquipmentCountChanges } from '../../components/EscalationOfferWizard/escalation-offer-utils'
import { EquipmentDate } from 'nemo-pricing'
import * as Utils from '../../../../common/common-utils'

export const updateReasonForCriticalityAnswers = (
  answersByGroup: AssessmentEquipmentAnswers | AssessmentEquipmentGroupAnswers,
  offer: Offer
) => {
  const allEquipment = R.mergeAll(
    R.map(building => R.indexBy(R.prop('salesToolId'), building.details.equipment), offer.buildings)
  )

  // Reason for criticality can only exist when the equipment has been set as critical
  return R.mapObjIndexed((answers: AssessmentEquipmentAnswers | AssessmentEquipmentGroupAnswers, equipmentId) => {
    const {
      CRITICALITY_FOR_CUSTOMERS_REASON,
      CRITICALITY_FOR_CUSTOMERS_REASON_ESCALATOR,
      CRITICALITY_FOR_CUSTOMERS_REASON_DOOR,
      ...otherAnswers
    } = answers
    return {
      ...otherAnswers,
      ...(allEquipment[equipmentId] && allEquipment[equipmentId].criticality === 'critical'
        ? {
            CRITICALITY_FOR_CUSTOMERS_REASON,
            CRITICALITY_FOR_CUSTOMERS_REASON_ESCALATOR,
            CRITICALITY_FOR_CUSTOMERS_REASON_DOOR
          }
        : undefined)
    }
  }, answersByGroup)
}

export const updateAssessmentAndEquipmentAnswers = (
  questions: AssessmentQuestion[],
  answersByGroup: AssessmentEquipmentAnswers | AssessmentEquipmentGroupAnswers,
  offer: Offer
) => {
  const answersFilteredByCriticality = updateReasonForCriticalityAnswers(answersByGroup, offer)

  // Go through questions and remove an answer if the question or choice has been removed
  const keys = questions.map(R.prop('key'))
  const questionsChoices = questions.map(q => ({ ...q, choices: q.choices.map(({ key }) => key) }))
  const questionsKeyed = R.groupBy(R.prop('key'), questionsChoices)
  // @ts-ignore
  return R.map(
    R.pipe(R.pick<PRecord<AssessmentQuestionKey, number>, AssessmentQuestionKey>(keys), answers =>
      R.pickBy((v, k) => R.find(c => c === v, questionsKeyed[k][0].choices) !== undefined, answers)
    ),
    answersFilteredByCriticality
  )
}

// NOTE: default values are supported for types 'select' and 'text'
export const updateCommercialTerms = (
  frontlineConfig: ClientFrontlineConfig,
  commercialTerms: CommercialTerms,
  opportunity: Opportunity,
  isContractStartDateAtEquipmentLevel: boolean
) => {
  const updatedCommercialTerms: CommercialTerms = R.reduce(
    (acc, { key, type, values, pair, default_value, is_multiselect, multiselect_default_values }) => {
      if (R.has(key, commercialTerms)) {
        const value: string | string[] | undefined | EquipmentDate = commercialTerms[key]
        switch (type) {
          case 'select':
            if (!Array.isArray(values)) {
              return acc
            }
            const isValueValid = (val: string | undefined): boolean => R.indexOf(val, values as any) !== -1
            if (isMultiSelectCommercialTerm(key)) {
              if (typeof value === 'string' && isValueValid(value as string)) {
                return R.assoc(key, [value], acc)
              }
              if (Array.isArray(value) && value.length) {
                const newValue: string[] = value.filter((val: string) => isValueValid(val))
                if (newValue.length) {
                  return R.assoc(key, newValue, acc)
                }
              }
              if (!R.isNil(default_value) || !R.isNil(multiselect_default_values)) {
                return is_multiselect && !R.isNil(multiselect_default_values)
                  ? R.assoc(key, multiselect_default_values, acc)
                  : R.assoc(key, [default_value], acc)
              } else {
                return R.assoc(key, [], acc)
              }
            }

            if (isValueValid(value as string)) {
              return R.assoc(key, value, acc)
            }
            if (!R.isNil(default_value)) {
              return R.assoc(key, default_value, acc)
            }
            return acc
          case 'pair-select':
            const pairValue = commercialTerms[pair!]
            const validValue = (values as any).find((x: any) => x.key === value && pairValue && x.value === pairValue)
            return validValue ? R.pipe(R.assoc(key, value), R.assoc(pair!, pairValue))(acc) : acc
          case 'date':
          case 'flexible-date':
            if (
              isContractStartDateAtEquipmentLevel &&
              !frontlineConfig.contactDateAtEquipmentLevel.active &&
              ['VBEGDAT', 'KCSM_BASE_MONTH_ESCALATION', 'VENDDAT', 'VABNDAT'].includes(key)
            ) {
              return R.assoc(key, null, acc)
            }
            return R.assoc(key, value, acc)
          default:
            return R.assoc(key, value, acc)
        }
      }
      if (is_multiselect && !R.isNil(multiselect_default_values)) {
        return R.assoc(key, multiselect_default_values, acc)
      }
      return R.isNil(default_value) ? acc : R.assoc(key, default_value, acc)
    },
    {},
    frontlineConfig.commercialTerms
  )

  return resetCommercialTerms(frontlineConfig, updatedCommercialTerms, opportunity)
}

export const updateIntroductionTexts = (
  frontlineConfig: ClientFrontlineConfig,
  introductionTexts: Record<string, string>
) => R.merge(frontlineConfig.introductionTexts, introductionTexts)

const updateExtraServices =
  (frontlineConfig: ClientFrontlineConfig) =>
  (offer: Offer): Offer => {
    const offering = toDomain(offer)
    const assessment = AssessmentResult.toDomain(offer, frontlineConfig)
    if (
      !(
        (assessment._tag === 'ValidForFlexible' || assessment._tag === 'ValidForFlexibleDoors') &&
        offering._tag === 'Flexible'
      )
    ) {
      return offer
    }
    const groups = collectGroups(assessment)
    return {
      ...offer,
      extraServices:
        offer.extraServices && offer.extraServices.length > 0
          ? fillPricesForExtraServices(extractServices(offering, groups, frontlineConfig), offer.extraServices)
          : extractServices(offering, groups, frontlineConfig),
      extraCommercialTerms:
        offer.extraCommercialTerms && offer.extraCommercialTerms.length > 0
          ? fillPricesForExtraCommercialTerms(
              extractCommercialTerms(offering, offer.commercialTerms, groups),
              offer.extraCommercialTerms
            )
          : extractCommercialTerms(offering, offer.commercialTerms, groups)
    }
  }

const updateOffering = (frontlineConfig: ClientFrontlineConfig) => (offer: Offer) => {
  const { offeringQuestions, enableDXOffering } = frontlineConfig
  const assessment = AssessmentResult.toDomain(offer, frontlineConfig)
  const offering = toDomain(offer)
  switch (assessment._tag) {
    case 'ValidForQuickTender':
      return offering instanceof QuickTender
        ? fromDomain(offer, Offering.repairOfferingSelections(offeringQuestions, offering, offer))
        : fromDomain(offer, new QuickTenderScopePending({}))
    case 'ValidForValueAddedServiceAddendum':
      // Assume that the offer has always been and will be a VAS addendum
      // if it has once been of that type.
      return fromDomain(offer, Offering.repairOfferingSelections(offeringQuestions, offering, offer))
    case 'ValidForGSMCampaign': // TODO: Remove as part of GSM Clean up
      // Assume that the offer has always been and will be a VAS addendum
      // if it has once been of that type.
      return fromDomain(offer, Offering.repairOfferingSelections(offeringQuestions, offering, offer))
    case 'ValidForFlexibleDoors':
      return fromDomain(offer, Offering.repairOfferingSelections(offeringQuestions, offering, offer))
    case 'ValidForFlexible':
      switch (offering._tag) {
        case 'QuickTender':
          // Continue with quicktender but fix selections
          const upgraded = upgradeToQuickTenderWithRatings(offering, assessment)
          const repaired = Offering.repairOfferingSelections(offeringQuestions, upgraded, offer)
          return fromDomain(offer, repaired)
        case 'QuickTenderWithRatings':
          // Continue with quick tender but fix selections
          return fromDomain(offer, Offering.repairOfferingSelections(offeringQuestions, offering, offer))
        case 'Flexible':
          return fromDomain(offer, Offering.repairOfferingSelections(offeringQuestions, offering, offer))
        case 'QuickTenderScopePending':
        case 'NoRatings':
        default:
          return fromDomain(
            offer,
            makeFlexibleOffering(
              offeringQuestions.group,
              offeringQuestions.interaction.flexible,
              assessment,
              offer,
              enableDXOffering.active
            )
          )
      }
    case 'Invalid':
    default:
      return fromDomain(offer, noRatings)
  }
}

const getCriticality = (
  equipment: Equipment,
  frontlineConfig: ClientFrontlineConfig,
  assessmentMethod: AssessmentMethodType,
  assessmentEquipmentAnswers: Record<string, PRecord<EquipmentQuestionKey, number>>
): Criticality => {
  const { criticality, equipmentCategory, salesToolId } = equipment
  if (assessmentMethod !== 'standard' || salesToolId == null) {
    return criticality
  }
  const criticalityKey = getCriticalityKey(equipmentCategory)
  const question = getQuestionForKey(criticalityKey, frontlineConfig, equipmentCategory)
  const assessmentAnswers = assessmentEquipmentAnswers[salesToolId]
  if (question === undefined || assessmentAnswers === undefined) {
    return 'normal'
  }
  const assessmentAnswer = assessmentAnswers[criticalityKey]
  const isCriticalChoiceVisible =
    assessmentAnswer !== undefined
      ? question.choices.some(c => c.key === assessmentAnswer && c.visible === true)
      : false
  return criticality === 'critical' && isCriticalChoiceVisible ? 'critical' : 'normal'
}

const getTechnicalId = (frontlineConfig: ClientFrontlineConfig, equipment: Equipment) =>
  equipment.equipmentCategory === 'elevator'
    ? frontlineConfig.elevatorQuestions.filter(obj => obj.key === 'technicalId').length === 0
      ? null
      : equipment.technicalId
    : equipment.technicalId
const updateEquipmentMasterData =
  (frontlineConfig: ClientFrontlineConfig) =>
  (offer: Offer): Offer => ({
    ...offer,
    buildings: offer.buildings.map(building => ({
      ...building,
      details: {
        ...building.details,
        equipment: building.details.equipment.map(e => ({
          ...e,
          criticality: getCriticality(e, frontlineConfig, offer.assessmentMethod, offer.assessmentEquipmentAnswers),
          technicalId: getTechnicalId(frontlineConfig, e),
          locationAddress: e.locationAddress || building.details.buildingAddress,
          locationCity: e.locationCity || building.details.buildingCity,
          locationCountry: e.locationCountry || building.details.locationCountry,
          locationPostalCode: e.locationPostalCode || building.details.locationPostalCode,
          locationRegion: e.locationRegion || building.details.locationRegion,
          equipmentAddress: e.equipmentAddress || building.details.buildingAddress
        }))
      }
    }))
  })

const applyBundles = (
  appliedBundles: AppliedBundle[],
  offer: Offer,
  frontlineConfig: ClientFrontlineConfig,
  allBundles: Bundle[]
): { newOffer: Offer; invalidKeys: BundleGroupInfo[] } => {
  const invalidKeys: BundleGroupInfo[] = []
  let newOffer = { ...offer }
  const { isGSMOffer } = newOffer
  appliedBundles.forEach(appliedBundle => {
    const offering = toDomain(newOffer)
    const bundle: Bundle | undefined = allBundles.find(eachBundle => eachBundle.key === appliedBundle.key)
    const groupQuestions = getGroupOfferingQuestionsByOffering(
      frontlineConfig.offeringQuestions,
      offering,
      newOffer.isGSMOffer // TODO: Remove as part of GSM Clean up
    )
    const statesByGroup = initGroupStates(
      groupQuestions,
      initGroupValidationStates(frontlineConfig.offeringQuestions, offering, offer)
    )
    const { groupId, equipmentCategory, key } = appliedBundle

    const isBundleValidForGSMandVAS =
      offer.opportunity.opportunityCategory === 'Value Added Service'
        ? (appliedBundle.is_gsm_campaign && isGSMOffer) || (appliedBundle.is_vas_addendum && !isGSMOffer)
        : true

    if (appliedBundle.isValid && bundle && isBundleValidForGSMandVAS) {
      const newSelections = toggleBundleSelections(frontlineConfig, offering, newOffer, statesByGroup, {
        groupId,
        criticality: 'normal',
        equipmentCategory,
        bundle
      })

      if (newSelections._tag === 'UpdateBundleSelections') {
        newOffer = {
          ...fromDomain(newOffer, newSelections.offering),
          bundleGroupSelection: newSelections.bundleServiceSelectionChange
        }
      } else if (newSelections._tag === 'UpdateNothing' && newSelections.error instanceof BundleUpdateError) {
        const services = newSelections.error.keys
        const reason = `${t('offerWizard.apply.package.error.text')} ${services.map(service =>
          t(`offerSelections:${service}.title`)
        )}`
        invalidKeys.push({
          bundleName: t(`bundles:${key}.title`),
          groupName: getGroupNameById(newOffer, groupId),
          reason
        })
        newOffer = R.dissocPath(['bundleGroupSelection', groupId, equipmentCategory], newOffer)
      }
    } else {
      invalidKeys.push({
        bundleName: t(`bundles:${key}.title`),
        groupName: getGroupNameById(newOffer, groupId),
        reason: t(`offerWizard.invalidPackage.reason`)
      })
      const removeSelectedServiceInOffer = resetServiceSelected(
        newOffer,
        appliedBundle,
        frontlineConfig,
        !appliedBundle.isValid
      )

      newOffer = R.dissocPath(['bundleGroupSelection', groupId, equipmentCategory], removeSelectedServiceInOffer)
    }
  })

  return { newOffer, invalidKeys }
}

function toggleBundleSelections(
  frontlineConfig: ClientFrontlineConfig,
  offering: Offering.Offering,
  offer: Offer,
  allStatesByGroup: GroupStates,
  path: BundlePath
): StateChange {
  switch (offering._tag) {
    case 'Flexible':
    case 'ValueAddedServicesAddendum':
      return applyOrRemoveBundleSelection(frontlineConfig, offering, offer, allStatesByGroup, path, false, [])
    default:
      return new NoOp(offering)
  }
}

const updateBundleSelection = (frontlineConfig: ClientFrontlineConfig) => (offer: Offer) => {
  const allBundles = frontlineConfig.elevatorBundles
  const bundleKeys = allBundles.map(bundle => bundle.key)
  const appliedBundles = getAppliedBundles(offer, bundleKeys)
  const { newOffer, invalidKeys } = applyBundles(appliedBundles, offer, frontlineConfig, allBundles)
  if (invalidKeys.length) {
    dialog.show(bundleDeactivationDialog(invalidKeys))
  }
  return newOffer
}

const resetServiceSelected = (
  offer: Offer,
  appliedBundle: AppliedBundle,
  frontlineConfig: ClientFrontlineConfig,
  isBundleDeleted: boolean
): Offer => {
  const { bundleGroupSelection, isGSMOffer } = offer
  const offering = toDomain(offer)
  const { groupId, equipmentCategory, key: bundleKey } = appliedBundle
  const groupQuestions = getGroupOfferingQuestionsByOffering(frontlineConfig.offeringQuestions, offering, isGSMOffer) // TODO: Remove as part of GSM Clean up
  const allStatesByGroup = initGroupStates(
    groupQuestions,
    initGroupValidationStates(frontlineConfig.offeringQuestions, offering, offer)
  )
  const bundleSelection: BundleSelection = R.path([groupId, equipmentCategory, bundleKey], bundleGroupSelection)
  const path =
    bundleSelection && !isBundleDeleted
      ? {
          key: bundleKey,
          category: equipmentCategory,
          serviceList: bundleSelection.serviceList,
          isActive: bundleSelection.isActive,
          version: bundleSelection.version,
          type: bundleSelection.type
        }
      : null
  if (!R.isNil(path)) {
    const bundleObj = path
    const { updatedOffering } = getUpdateOffering(
      frontlineConfig,
      offering,
      offer,
      allStatesByGroup,
      {
        groupId,
        criticality: 'normal',
        equipmentCategory,
        bundle: bundleObj as Bundle
      },
      true,
      []
    )
    return fromDomain(offer, updatedOffering)
  } else {
    return offer
  }
}

const updateContractAttachments =
  (frontlineConfig: ClientFrontlineConfig) =>
  (offer: Offer): Offer => {
    const attachments = Array.isArray(offer.attachments)
      ? R.intersection(offer.attachments, frontlineConfig.attachmentOptions)
      : undefined
    const customAttachmentSelection = Array.isArray(offer.customAttachmentSelection)
      ? R.intersection(offer.customAttachmentSelection, frontlineConfig.customAttachment)
      : []

    return {
      ...offer,
      attachments,
      customAttachmentSelection,
      customAttachmentComments: !R.isEmpty(customAttachmentSelection) ? offer.customAttachmentComments : ''
    }
  }

export const updateOfferConfiguration = (frontlineConfig: ClientFrontlineConfig, offer: Offer): Offer => {
  const { assessmentQuestions, enableVasSalesDiscount } = frontlineConfig

  const allEquipmentQuestions = (assessmentQuestions.elevator.all as EquipmentAssessmentQuestion[])
    .concat(assessmentQuestions.escalator.all)
    .concat(assessmentQuestions.door.all)
    // The questions of XL and normal may be very different for doors, so we have to do a bit of wrangling
    .concat(
      assessmentQuestions.door_xl.all.map(q => ({
        ...q,
        key: translateXLAssessmentQuestionAnswerKey(q.key),
        subject: 'door'
      })) as DoorAssessmentQuestion[]
    )

  const { equipmentsInOpportunity, equipmentsInOffer, isEquipmentEnabledInOffer, contractEquipmentsTotalAnnualValue } =
    getEquipmentCountChanges(offer.opportunity, offer.buildings, offer, frontlineConfig)

  const updatedOffer = {
    ...offer,
    isVasDiscountEnabledInFL: enableVasSalesDiscount.active,
    equipmentsInOpportunity,
    equipmentsInOffer,
    isEquipmentEnabledInOffer,
    contractEquipmentsTotalAnnualValue
  }

  return R.pipe(
    (o: Offer) => ({
      ...o,
      assessmentEquipmentGroupAnswers: updateAssessmentAndEquipmentAnswers(
        offer.assessmentMethod === 'standard' ? assessmentQuestions.building.all : assessmentQuestions.group.all,
        offer.assessmentEquipmentGroupAnswers,
        o
      )
    }),
    o =>
      // @ts-ignore
      ({
        ...o,
        assessmentEquipmentAnswers: updateAssessmentAndEquipmentAnswers(
          allEquipmentQuestions,
          offer.assessmentEquipmentAnswers,
          // @ts-ignore
          o
        )
      } as Offer),
    updateEquipmentMasterData(frontlineConfig),
    updateOffering(frontlineConfig),
    o =>
      ({
        ...o,
        commercialTerms: updateCommercialTerms(
          frontlineConfig,
          offer.commercialTerms || {},
          offer.opportunity,
          offer.isContractStartDateAtEquipmentLevel
        ),
        introductions: updateIntroductionTexts(frontlineConfig, offer.introductions),
        isContractStartDateAtEquipmentLevel: frontlineConfig.contactDateAtEquipmentLevel.active
          ? offer.isContractStartDateAtEquipmentLevel
          : false
      } as Offer),
    updateExtraServices(frontlineConfig),
    setInitialWarrantyOptions(frontlineConfig) as (o: Offer) => Offer,
    updateBundleSelection(frontlineConfig),
    normalizeOfferForCriticality(),
    updateContractAttachments(frontlineConfig)
  )(updatedOffer)
}

function resetEquipmentProductOfferingIds(offer: Offer) {
  const productOfferingsIdLens = L.compose(
    L.prop('buildings'),
    L.sequence,
    L.prop('details'),
    L.prop('equipment'),
    L.sequence,
    L.prop('productOfferingId')
  )

  return L.set(productOfferingsIdLens, null, offer)
}

export const createNextOfferVersion = (offer: Offer, frontlineConfig: ClientFrontlineConfig) => {
  const nextOffer = R.pipe(
    R.merge(offer),
    resetEquipmentProductOfferingIds
  )({
    prices: [],
    summaryAttachmentId: null,
    tenderVersionId: null,
    tenderVersionName: null,
    tenderVersionNumber: null,
    tenderVersionComment: '',
    completed: false,
    contractEmailTimestamp: undefined,
    id: Utils.getUUID(),
    enablerFilename: undefined,
    contractFilenames: { docgen: {}, gendoc: {} },
    sapContractTimestamp: undefined,
    sapContractFilenames: [],
    salesforceTenderTimestamp: null,
    finalized: false,
    sfTenderVersionActive: false,
    discountApprovalStatus: 'pending',
    discountApprovalComment: null,
    discountSummary: false,
    finalization_started: null,
    vasSetupFee: undefined,
    priceEstimation: undefined
  } as Partial<Offer>)

  return setInitialData(true, frontlineConfig)(updateOfferConfiguration(frontlineConfig, nextOffer))
}

const reset = (frontlineConfig: ClientFrontlineConfig) => (offer: Offer) => {
  const { completed } = offer

  if (!completed) {
    return {}
  }

  return createNextOfferVersion(offer, frontlineConfig)
}

export const setOfferModified = (offer: Offer) =>
  R.merge(offer, {
    modified_client: moment.utc().format(),
    completed: false,
    finalizationProgress: {}
  })

export const getUpdatedOfferFields = (offer: Offer, frontlineConfig: ClientFrontlineConfig) =>
  R.pipe(reset(frontlineConfig), setOfferModified)(offer)

const shouldNormalizeGroup = (groupEquipments: Equipment[]): Record<string, boolean> => {
  const groupHasCriticalEquipments = groupEquipments.some(e => e.criticality === 'critical')
  const groupHasNormalEquipments = groupEquipments.some(e => e.criticality === 'normal')
  return {
    groupHasNormalEquipments,
    groupHasCriticalEquipments
  }
}

type EquipmentTypesInGroup = 'elevator' | 'escalator' | 'door'

const getNormalizedGroups = (
  currentGroup: EquipmentGroup,
  groupId: EquipmentGroupStId,
  groupName: string,
  isNewGroup: boolean = false,
  modifySameGroup: boolean = false
): EquipmentGroups => {
  const normalizedGroup = Object.keys(currentGroup)
    .filter((k: EquipmentTypesInGroup) => ['elevator', 'escalator', 'door'].includes(k))
    .reduce(
      (normalizedEqGroup: EquipmentGroup, equipmentType: EquipmentTypesInGroup) => {
        const { normal, critical } = currentGroup[equipmentType] as Grouping
        const copySection = isNewGroup || modifySameGroup ? critical : normal
        const normalizedEquipment = copySection
          ? ({
              [equipmentType]: { normal: { ...copySection } }
            } as Grouping)
          : {}
        return { ...normalizedEqGroup, ...normalizedEquipment }
      },
      {
        name: groupName,
        salesToolId: groupId,
        groupKind: currentGroup.groupKind
      }
    )
  return {
    [groupId]: { ...normalizedGroup }
  }
}

const getUpdatedGroups = (
  equipmentGroups: EquipmentGroups,
  currentGroup: EquipmentGroup,
  groupId: EquipmentGroupStId,
  newGroupId: EquipmentGroupStId
): EquipmentGroups => {
  const isGroupWithOnlyCriticalEquipment = groupId === newGroupId
  const newGroup = !isGroupWithOnlyCriticalEquipment
    ? getNormalizedGroups(
        currentGroup,
        newGroupId,
        `${currentGroup.name}-${t('xlAssessmentWizard.equipment.critical')}`,
        true,
        isGroupWithOnlyCriticalEquipment
      )
    : {}
  const existingGroup = getNormalizedGroups(
    currentGroup,
    groupId,
    currentGroup.name,
    false,
    isGroupWithOnlyCriticalEquipment
  )

  return {
    ...equipmentGroups,
    ...existingGroup,
    ...newGroup
  }
}

const updateEquipmentData = (offer: Offer, criticalEquipmentIds: string[], newGroupId: EquipmentGroupStId) => {
  const assessmentEquipmentAnswers = { ...offer.assessmentEquipmentAnswers }
  const buildings = offer.buildings.map((building: Building) => ({
    ...building,
    details: {
      ...building.details,
      equipment: building.details.equipment.map((e: Equipment) => {
        if (criticalEquipmentIds.includes(e.salesToolId)) {
          if (assessmentEquipmentAnswers[e.salesToolId]) {
            delete assessmentEquipmentAnswers[e.salesToolId].CRITICALITY_FOR_CUSTOMERS
            delete assessmentEquipmentAnswers[e.salesToolId].CRITICALITY_FOR_CUSTOMERS_DOOR
            delete assessmentEquipmentAnswers[e.salesToolId].CRITICALITY_FOR_CUSTOMERS_ESCALATOR
            delete assessmentEquipmentAnswers[e.salesToolId].CRITICALITY_FOR_CUSTOMERS_REASON
            delete assessmentEquipmentAnswers[e.salesToolId].CRITICALITY_FOR_CUSTOMERS_REASON_DOOR
            delete assessmentEquipmentAnswers[e.salesToolId].CRITICALITY_FOR_CUSTOMERS_REASON_ESCALATOR
            delete assessmentEquipmentAnswers[e.salesToolId].CRITICALITY_FOR_CUSTOMERS_REASON_XL_DOOR
            delete assessmentEquipmentAnswers[e.salesToolId].CRITICALITY_FOR_CUSTOMERS_REASON_XL_ELEVATOR
            delete assessmentEquipmentAnswers[e.salesToolId].CRITICALITY_FOR_CUSTOMERS_REASON_XL_ESCALATOR
            delete assessmentEquipmentAnswers[e.salesToolId].CRITICALITY_FOR_CUSTOMER_REASON_XL_PEOPLEFLOW
          }
          return { ...e, groupId: newGroupId, criticality: 'normal' as Criticality }
        }
        return { ...e }
      })
    }
  }))

  return { assessmentEquipmentAnswers, buildings }
}

const getCriticalEquipmentIds = (groupEquipments: Equipment[]) =>
  groupEquipments.filter(e => e.criticality === 'critical').map(e => e.salesToolId)

const getEquipmentsByGroup = (offer: Offer) => {
  const allEquipments = R.chain(b => R.pathOr([] as Equipment[], ['details', 'equipment'], b), offer.buildings)
  return R.groupBy(R.prop<Equipment, 'groupId'>('groupId'))(allEquipments)
}

export const normalizeOfferForCriticality =
  () =>
  (offer: Offer): Offer => {
    if (offer.normalizedForCriticality || (offer.isEscalationFlow && offer.isEscalationFlow.active)) {
      return offer
    }

    const equipmentByGroup = getEquipmentsByGroup(offer)
    let updatedOffer = { ...offer, normalizedForCriticality: true }
    let groupNormalised: boolean = false

    Object.keys(equipmentByGroup).forEach(groupId => {
      const groupEquipments = equipmentByGroup[groupId]
      const { groupHasCriticalEquipments, groupHasNormalEquipments } = shouldNormalizeGroup(groupEquipments)
      if (groupHasCriticalEquipments) {
        groupNormalised = true
        const currentGroup = { ...updatedOffer.equipmentGroups[groupId] }
        const criticalEquipmentIds = getCriticalEquipmentIds(groupEquipments)
        const newGroupId = groupHasNormalEquipments ? Utils.getUUID() : groupId
        const equipmentGroups = getUpdatedGroups(updatedOffer.equipmentGroups, currentGroup, groupId, newGroupId)

        const assessmentEquipmentGroupAnswers = updatedOffer.assessmentEquipmentGroupAnswers[groupId]
          ? {
              ...updatedOffer.assessmentEquipmentGroupAnswers,
              [newGroupId]: updatedOffer.assessmentEquipmentGroupAnswers[groupId]
            }
          : updatedOffer.assessmentEquipmentGroupAnswers

        const { assessmentEquipmentAnswers, buildings } = updateEquipmentData(
          updatedOffer,
          criticalEquipmentIds,
          newGroupId
        )

        updatedOffer = {
          ...updatedOffer,
          assessmentEquipmentGroupAnswers,
          assessmentEquipmentAnswers,
          buildings,
          equipmentGroups
        }
      }
    })

    if (groupNormalised) {
      const onPrimary = () => {
        const isOnline = IsOnline.get()
        if (isOnline) {
          sync()
        }
      }

      dialog.show(renderUserNotificationDialog('offerNormalized.title', 'offerNormalized.message', onPrimary))
    }

    return {
      ...updatedOffer,
      modified_client: groupNormalised ? moment.utc().format() : updatedOffer.modified_client
    }
  }
